.navBar {
    position: absolute;
    top: 0;
    left: 0;
    padding: 10px;
}

.navBar-inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
}

.icon {
    width: 28px;
    height: 28px;
    color: #5aa8fc;
}

.icon.light {
    color: #0b69da;
}