.button-group {
    display: flex;
    justify-content: space-around;
    background: #ddf4ff;
    padding: 0em;
    border-radius: 15px;
}

.button-group.light {
    background: #ddf4ff;
}

.button1 {
    padding: 16px 33px;
    border-radius: 5px;
    background: #8b9ba2;
    color: #062d5d;
    border: none;
    font-size: .9em;
    font-family: inherit;
    text-align: center;
    cursor: pointer;
    transition: 0.4s;
}

.button1.light {
    background: #ddf4ff;
    color: #0b69da;
    opacity: 1;
}

.button1.dark {
    background: #3081f7;
    color: #eee;
    opacity: 1;
}

.button1.selected {
    background: #0b69da;
    color: #eee;
}

.button1:hover {
    box-shadow: inset 0px 1px 0px 0px rgba(255, 255, 255, 0.2),
        inset 0px -2px 0px 0px rgba(0, 0, 0, 0.2),
        0px 0px 180px 0px #0b69da;
}

.button1:hover.light {
    box-shadow: 7px 5px 56px -14px var(--color-tertiary);
}

.button1:active {
    transform: scale(0.97);
    box-shadow: 7px 5px 56px -10px #0b69da;
}

.outline-button {
    padding: 13px 28px;
    border-radius: 5px;
    background: none;
    background: #172948;
    border: 1px solid #294e7d;
    color: #67abffdf;
    font-size: .9em;
    font-family: inherit;
    text-align: center;
    cursor: pointer;
    transition: 0.4s;
}

.outline-button.light {
    background: #67abff3f;
    border: 1px solid #67abffdf;
    color: #3081f7;
}

.underline-button {
    border-radius: 15px;
    background: none;
    border: none;
    color: #5aa8fc;
    font-size: 1em;
    font-family: inherit;
    text-align: center;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    text-decoration: underline;
    text-underline-offset: 0.1em;
}

.underline-button.light {
    color: #3081f7;
}

.underline-button:hover {
    color: #3081f7;
    text-underline-offset: 0.3em;
    transform: translateY(-3px);
}

.underline-button:active {
    transform: scale(0.97);
}
  
.spinner {
    border: 2px solid #5aa8fc5f;
    border-left-color: transparent;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}
  
@keyframes spin {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
}

.example-button {
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 8px;
    padding-top: 8px;
    border-radius: 15px;
    background: #172948;
    color: #5aa8fc;
    border: 1px solid #294e7d;
    font-size: .8em;
    font-family: inherit;
    text-align: center;
    cursor: pointer;
    transition: 0.4s;
}

.example-button.big {
    font-size: 1.2em;
}

.example-button.big.selected {
    font-size: 1.2em;
    background: #3081f7;
    color: white;
}

.example-button.light {
    background: #ddf4ff;
    color: #0b69da;
    border-color: #67abff;
}

.example-button.selected {
    background: #0b69da;
    color: #eee;
}

.example-button.selected.light {
    background: #0b69da;
    color: white;
}

.example-button:hover {
    /* box-shadow: 7px 5px 56px -14px var(--color-quinary); */
    box-shadow: inset 0px 1px 0px 0px rgba(255, 255, 255, 0.1),
        inset 0px -2px 0px 0px rgba(0, 0, 0, 0.2),
        0px 0px 180px 0px var(--color-tertiary);
    transform: translateY(-2px);
}

.example-button:hover.light {
    box-shadow: 7px 5px 56px -14px var(--color-tertiary);
}

.nav-button {
    padding: 10px;
    border-radius: 55px;
    background: #172948;
    color: #5aa8fc;
    border: 1px solid #294e7d;
    font-size: .8em;
    font-family: inherit;
    text-align: center;
    cursor: pointer;
    transition: 0.4s;
}

.nav-button:hover .nav-button-text {
    transition: 0.4s ease-in-out;
    transform: scale(1.1);
}

.nav-button.big {
    font-size: 1.2em;
}

.nav-button.light {
    background: #ddf4ff;
    color: #0b69da;
    border-color: #67abff;
}

.slider {
    --slider-width: 100%;
    --slider-height: 12px;
    --slider-bg: var(--color-tertiary);
    --slider-border-radius: 999px;
    --level-color: var(--color-secondary);
    --level-transition-duration: .1s;
    --icon-margin: 15px;
    --icon-color: var(--slider-bg);
    --icon-size: 25px;
}

.slider.light {
    --slider-bg: var(--color-secondary);
    --level-color: var(--color-tertiary);
    --icon-color: var(--slider-bg);
}

.slider-title {
    font-size: 1em;
    font-weight: 700;
    margin-bottom: 0.5em;
    color: #888;
    /* color: var(--color-primary); */
}

.age-highlight {
    font-size: 1.25em;
    color: var(--color-secondary);
}

.age-highlight.light {
    color: var(--color-tertiary);
}

.slider-title.light {
    color: #ffffffcf;
}
  
.slider {
    cursor: pointer;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
  
.slider .volume {
    display: inline-block;
    vertical-align: top;
    margin-right: var(--icon-margin);
    color: var(--icon-color);
    width: var(--icon-size);
    height: auto;
}

.slider .level {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: var(--slider-width);
    height: var(--slider-height);
    background: var(--slider-bg);
    overflow: hidden;
    border-radius: var(--slider-border-radius);
    -webkit-transition: height var(--level-transition-duration);
    -o-transition: height var(--level-transition-duration);
    transition: height var(--level-transition-duration);
    cursor: inherit;
}
  
.slider .level::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 0;
    height: 0;
    -webkit-box-shadow: -200px 0 0 200px var(--level-color);
    box-shadow: -200px 0 0 200px var(--level-color);
}
  
.slider:hover .level {
    height: calc(var(--slider-height) * 2);
}

.input {
    font-size: 1em;
    padding: 1em .6em;
    font-weight: 600;
    color: #fff;
    border: none;
    border-radius: 5px;
    background: #03060a;
    opacity: 1;
    width: 80%;
    font-family: inherit;
}

/* add #3081f7 to input focus */
.input:focus {
    outline: none;
    box-shadow: 0px 0px 0px 1px #3081f76f;
}

.input::placeholder {
    color: #69707b;
    opacity: 0.7;
}

.input.light {
    background: #ddf4ff;
    color: #111;
    opacity: 1;
}

.input.light::placeholder {
    color: #0b69da;
}

.input:focus {
    outline: none;
}

.toggle-switch-container {
    position: absolute;
    top: 0.5em;
    right: 3em;
    --toggle-background: #172948;
    --toggle-highlight: #5aa8fc;
    --toggle-border: #294e7d;
}

.toggle-switch-container.light {
    --toggle-background: #172948;
    --toggle-highlight: #5aa8fc;
    --toggle-border: #5aa8fc;
}

.toggle-switch {
    position: relative;
    width: 60px;
    height: 30px;
}
  
.switch-label {
    position: absolute;
    width: 100%;
    height: 30px;
    background-color: var(--toggle-background);
    border-radius: 25px;
    cursor: pointer;
    border: 2px solid var(--toggle-border);
}
  
.checkbox {
    position: absolute;
    display: none;
}
  
.toggle-slider {
    position: absolute;
    width: 100%;
    left: 0px;
    height: 100%;
    border-radius: 25px;
    -webkit-transition: 0.3s;
    transition: 0.3s;
}
  
.checkbox:checked ~ .toggle-slider {
    background-color: var(--toggle-highlight);
}
  
.toggle-slider::before {
    content: "";
    position: absolute;
    top: 2.5px;
    left: 2.5px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    -webkit-box-shadow: inset 12px -4px 0px 0px var(--toggle-highlight);
    box-shadow: inset 12px -4px 0px 0px var(--toggle-highlight);
    background-color: var(--toggle-background);
    -webkit-transition: 0.3s;
    transition: 0.3s;
}
  
.checkbox:checked ~ .toggle-slider::before {
    -webkit-transform: translateX(50px);
    -ms-transform: translateX(50px);
    transform: translateX(30px);
    background-color: var(--toggle-background);
    -webkit-box-shadow: none;
    box-shadow: none;
}

.radio-wrapper {
    --font-color-dark: #323232;
    --font-color-light: #FFF;
    --bg-color: #fff;
    --main-color: #323232;
    position: relative;
    width: 250px;
    height: 36px;
    background-color: var(--bg-color);
    border: 2px solid var(--main-color);
    border-radius: 34px;
    display: flex;
    flex-direction: row;
    box-shadow: 4px 4px var(--main-color);
}
  
.radio-option {
    width: 80.5px;
    height: 28px;
    position: relative;
    top: 2px;
    left: 2px;
}
  
.radio-input {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    appearance: none;
    cursor: pointer;
}
  
.radio-btn {
    width: 100%;
    height: 100%;
    background-color: var(--bg-color);
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
  
.radio-span {
    color: var(--font-color-dark);
  }
  
.radio-input:checked + .radio-btn {
    background-color: var(--main-color);
}
  
.radio-input:checked + .radio-btn .radio-span {
    color: var(--font-color-light);
}