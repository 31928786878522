.AccountInterface {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10vh;
    height: 100vh;
    /* width: 50%; */
}

.AccountInterface-main {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.AccountInterface-main-account {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40em;
    height: 100%;
}

.AccountInterface-main-title {
    font-size: 2em;
}

.AccountInterface-main-subtitle {
    font-size: 1.35em;
    margin-top: -10px;
    opacity: 0.6;
}

.AccountInterface-main-points {
    font-size: 2.5em;
    color: #5aa8fc;
}

.AccountInterface-main-points.light {
    color: #294e7d;
}

.AccountInterface-main-points-text {
    font-size: .7em;
    opacity: 0.6;
    color: #fff;
}

.AccountInterface-main-points-text.light {
    color: #111;
    opacity: 0.4;
}

.AccountInterface-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
    width: 30em;
    height: 100%;
}

@media screen and (max-width: 768px) {
    .AccountInterface-form {
        width: 25em;
    }
}

.AccountInterface-form-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5em;
    width: 100%;
}

.AccountInterface-form-header-title {
    font-size: 2em;
}

.AccountInterface-form-header-error {
    color: #dc413d;
    margin: 0;
}

.AccountInterface-form-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5em;
}

.AccountInterface-form-footer-text {
    font-size: 1em;
}