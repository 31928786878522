.App {
  --color-primary: #8ecae6;
  --color-secondary: #219ebc;
  --color-tertiary: #023047;
  --color-quaternary: #ffb703;
  --color-quinary: #fb8500;
  --text-color: #ffffff;

  text-align: center;
  background-color: #0d1217;
  height: 100vh;
  min-height: 100vh;
  min-width: 100vw;  
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  position: relative;
  overflow-y: scroll;
}

.App::-webkit-scrollbar {
  display: none;
}

.App.light {
  background-color: #bcd6fa;
  color: black;
}