.loader {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 80px;
  z-index: 1;
}
  
.loader::before, 
.loader::after {
  content: '';
  position: absolute;
  width: inherit;
  height: inherit;
  border-radius: 50%;
  mix-blend-mode: multiply;
  animation: rotate92523 2s infinite cubic-bezier(0.77, 0, 0.175, 1);
}
  
.loader::before {
  background-color: #3081f7;
}
  
.loader::after {
  background-color: var(--color-tertiary);
  animation-delay: 1s;
}

.loader.light::before {
  background-color: #3081f7;
}

.loader.light::after {
  background-color: #ddf4ff;
}
  
@keyframes rotate92523 {
  0%, 100% {
    left: 55px;
  }
  
  25% {
    transform: scale(.3);
  }

  50% {
    left: 0%;
  }

  75% {
    transform: scale(1);
  }
}