.SavedPathsInterface {
    display: flex;
    margin-top: 40px;
}

.SavedPathsInterface-main {
    display: flex;
    flex-direction: column;
}

.SavedPathsInterface-main-inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.path {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 20px;
    padding: 0px 15px;
    background-color: #151b22;
    border-radius: 10px;
    position: relative;
}

.path.light {
    background-color: #fff;
    color: #111;
}

.path-title {
    font-size: 1.4em;
    margin-bottom: 10px;
    margin-top: 15px;
}

.path-description {
    font-size: 1.1em;
    opacity: 0.6;
    margin-top: 0;
    text-align: left;
    max-width: 50em;
}

.path-steps {
    font-size: 1.2em;
    margin-top: 0;
    position: absolute;
    top: 10px;
    right: 10px;
    color: #1f883d;
    font-weight: 600;
}

.path-steps.light {
    color: #1f883d;
}

.path-steps-text {
    font-size: .8em;
    margin-top: 0;
    margin-bottom: 0;
}

.path-methods {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;
    width: 100%;
    margin-top: 0;
    margin-bottom: 15px;
}