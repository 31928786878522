.MainInterface {
    display: flex;
    flex-direction: column;
    margin-top: 1.8em;
    width: 65%;
    border-radius: 1em;
    margin-bottom: 6em;
}

.MainInterface.light {
    background-color: #fff;
}

.MainInterface-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 2em 0em;
}

.MainInterface-footer-inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1em;
}

.MainInterface-error {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 1.8em;
    width: 65%;
    border-radius: 1em;
}

.MainInterface-error h2 {
    font-size: 1.5em;
    color: #fff;
    margin: 0;
    padding: 0;
    margin-top: 1em;
}

.MainInterface-error.light h2 {
    color: #111;
}

.MainInterface-error h3 {
    font-size: 1.3em;
    color: #fff;
    padding: 0;
    margin-top: 0.5em;
}

.MainInterface-error.light h3 {
    color: #111;
}

.MainInterface-error p {
    font-size: 1.2em;
    color: #ddd;
    padding: 0;
    margin-top: 0.5em;
}

.MainInterface-error.light p {
    color: #333;
}

.MainInterface-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 0em 1.5em;
    padding-bottom: 2em;
}

.MainInterface-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1em;
    padding: 0em 1em;
    margin-bottom: 0em;
    position: relative;
}

.experienceIcon {
    position: absolute;
    right: 1em;
    top: 1em;
    background-color: #172948;
    padding: 0.5em 1em;
    border-radius: 1em;
}

.experienceIcon.light {
    background-color: #ddf4ff;
}

.experienceIconText {
    font-size: 1.1em;
    padding: 0;
    margin: 0;
    color: #5aa8fc; 
}

.experienceIconText.light {
    color: #0b69da;
}

.mainTitle {
    font-size: 2em;
    color: #fff;
    max-width: 70%;
    text-align: left;
}

.mainTitle.light {
    color: #111;
}

.mainIcon {
    font-size: 2.5em;
    padding: 0;
    margin: 0;
    color: #fff;
}

.mainIcon.light {
    color: #111;
}

.mainDescription {
    font-size: 1.3em;
    text-align: left;
    width: 100%;
    color: #fff;
    margin-top: -0.5em;
    margin-bottom: 0;
}

.mainDescription.light {
    color: #111;
}

.divider {
    width: 100%;
    height: 1px;
    background-color: #fff;
    opacity: 0.5;
    margin: 1.5em 0em;
}

.divider.light {
    background-color: #111;
}

.roadmap {
    list-style: none;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.roadmapContainer {
    position: relative;
    width: 100%;
}

.roadmapLine {
    position: absolute;
    top: 0%;
    bottom: 0;
    left: 15px;
    width: 2.5px;
    background: #92969b;
}

.stepCheckbox {
    position: absolute;
    left: -20px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.step {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: #151b22;
    border: 2px solid #30363d;
    border-radius: 10px;
    position: relative;
    width: 100%;
}

.step.light {
    background: #ffffff;
    border: 2px solid #d0d7de;
}

.step-header {
    background: #1d222a;
    border-bottom: 2px solid #30363d;
    border-radius: 10px;
    width: 100%;
    text-align: left;
    margin-bottom: 0em;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;    
}

.step-header.light {
    background: #f6f8fa;
    border-bottom: 2px solid #d0d7de;
}

.step-toggle {
    position: absolute;
    right: 1em;
    border: 1px solid #96999b;
    padding: 0.5em 1em;
    border-radius: .5em;
}

.step-header h3 {
    font-size: 1.2em;
    color: #fff;
    padding-left: .7em;
}

.step-header.light h3 {
    color: #111;
}

.step-header h3 .duration {
    font-size: 1em;
    color: #96999b;
    margin-left: 0.3em;
}

.step-inner {
    padding: 0em 1em;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 96.5%;
    gap: 0.5em;
}

.step-inner p {
    font-size: 1.2em;
    text-align: left;
    color: #fff;
}

.step-inner.light p {
    color: #111;
}

.resources {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    padding-bottom: 1em;
}

.resource {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding: 12px 10px;
    margin: 0;
    border-radius: 5px;
    border: 1px solid #30363d;
    transition: background 0.2s;
}

.resource.light {
    background: transparent;
    border: 1px solid #d0d7de;
}

.resource-inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
}

.resource h4 {
    font-size: 1.2em;
    color: #fff;
    text-align: left;
    margin-top: -0.1em;
}

.resource.light h4 {
    color: #111;
}

.resource p {
    font-size: 1.1em;
    color: #acadad;
    text-align: left;
    margin: 0;
    margin-top: -.7em;
}

.resource.light p {
    color: #96999b;
}

.platformIcon {
    background-color: #d1e7dd;
    padding: 0.5em 1em;
    border-radius: 1em;
}

.platformIcon h3 {
    color: #1b7f37;
    font-size: 1.1em;
    padding: 0;
    margin: 0;
}

.platformIcon.youtube {
    background-color: #f8d7da;
}

.platformIcon.youtube h3 {
    color: #721c24;
}

.platformIcon.udemy {
    background-color: #fff3cd;
}

.platformIcon.udemy h3 {
    color: #856404;
}

.platformIcon.book {
    background-color: #cce5ff;
}

.platformIcon.book h3 {
    color: #004085;
}

.platformIcon.podcast {
    background-color: #f2dab1;
}

.platformIcon.podcast h3 {
    color: #664703;
}

.platformIcon.article {
    background-color: #fff;
}

.platformIcon.article h3 {
    color: #111;
}

.resourceLink {
    font-size: 1.2em;
    color: #111;
    text-decoration: none;
    width: 100%;
    height: 100%;
}

.resource:hover {
    background: #30363d;
}

.resource.light:hover {
    background: #dcdcdc;
}