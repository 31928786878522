.InitialInterface {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 1em;
}

.InitialInterface-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 5vh;
}

.InitialInterface-header-text {
    font-size: 2.2em;
    font-weight: bold;
    color: var(--color-fourteenary);
    color: white;
}

.InitialInterface-header-text.light {
    color: black;
}

.InitialInterface-text {
    font-size: 1.2em;
    font-weight: bold;
    color: var(--color-fourteenary);
    color: white;
}

.InitialInterface-text.light {
    color: #333;
}

.InitialInterface-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5vh;
    margin-top: 5vh;
}

.InitialInterface-main-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 1vh;
}

.InitialInterface-main-section-title {
    text-align: left;
    align-self: flex-start;
    font-size: 0.9em;
    opacity: 0.5;
    color: white;
    margin: 0;
    margin-top: -0.7em;
    margin-bottom: 0.4em;
}

.InitialInterface-main-section-text {
    font-size: 1.2em;
    font-weight: 600;
    /* margin-bottom: 2vh; */
    color: white;
}

.InitialInterface-main-section-text.light {
    color: black;
}

.InitialInterface-main-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* margin-bottom: 1vh; */
    gap: 1vw;
    width: 100%;
}

@keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
}

@keyframes sideToSide {
    0% { transform: translate(0px, 0px); }
    25% { transform: translate(5px, 0px); }
    50% { transform: translate(-5px, 0px); }
    75% { transform: translate(5px, 0px); }
    100% { transform: translate(0px, 0px); }
}

.shake {
    animation: sideToSide 0.6s cubic-bezier(.36,.07,.19,.97) both;
}

.example-buttons-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    margin-bottom: 0.2em;
    gap: 0.5em;
}